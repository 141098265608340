import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import HomeView from './components/views/HomeView.vue'
import ProjectView from './components/views/ProjectView.vue'

const routes = [
    { path: '/', component: HomeView },
    { path: '/:oid', component: ProjectView }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

createApp(App).use(router).mount('#app')
