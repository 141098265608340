import { API } from '../api';
import { Asset, AssetType } from './types'

const resourcePath = 'assets';

export class AssetService extends API {

    getAssets() {
        let query = `${resourcePath}`;
        return this.request<Asset[]>(query, Asset);
    }

    getAsset(oid: string) {
        let query = `${resourcePath}/${oid}/data`;
        return this.request<Asset>(query, Asset);
    }

    getAssetURL(asset: Asset) {
        let url = `${this.basePath}${resourcePath}/${asset.oid}/`;
        switch(asset.type) {
            case AssetType.IMAGE:
            case AssetType.ENVMAP:
            case AssetType.AUDIO:
                url += encodeURIComponent(asset.filename);
                break;
            case AssetType.OBJECT3D:
                url += 'converted/scene.glb';
                break;
            case AssetType.VIDEO:
                url += 'converted/video.mp4';
                break;
        }
        url += `?token=${this.authToken}`;
        return url;
    }

}