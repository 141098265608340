import { API } from '../api';
import { Project } from './types'

const resourcePath = 'projects';

export class ProjectService extends API {

    getProjects() {
        let query = `${resourcePath}?withDatasheets=true`;
        return this.request<Project[]>(query, Project);
    }

    getProject(oid: string) {
        let query = `${resourcePath}/${oid}`;
        return this.request<Project>(query, Project);
    }

}

