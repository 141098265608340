<template>
    <iframe v-if="project" ref="onirixFrame" id="onirix-frame" class="web_ar_iframe" :src="`https://studio.onirix.com/projects/${project?.oid}/webar?token=${project?.publicToken}`" allow="camera;gyroscope;accelerometer;geolocation"></iframe>
    <ImageComparator v-if="activeImageComparator?.valid" :imageComparator="activeImageComparator" @close="onImageComparatorClose()"></ImageComparator>
</template>

<script>

import ImageComparator from '../shared/ImageComparator.vue'

import OnirixAPI from 'onirix-api'
import Constants from '../../constants'

const OnirixAPIClient = new OnirixAPI(Constants.API_TOKEN, `${Constants.ONIRIX_HOST}/api/`);

export default {
    name: 'ProjectView',
    components: { ImageComparator },
    data() {
        return {
            project: null,
            assets: [],
            imageComparators: {},
            activeImageComparator: null,
            messageListener: (msg) => this.onMessageReceived(msg)
        }
    },

    async created() {
        const projectOid = this.$route.params.oid;
        this.project = await OnirixAPIClient.getProject(projectOid);
        this.assets = await OnirixAPIClient.getAssets();
        window.addEventListener('message', this.messageListener); 

        this.$nextTick(() => {
            let devicemotionScript = document.createElement('script');
            devicemotionScript.setAttribute('src', 'https://www.onirix.com/webar/ox-devicemotion.min.js');
            document.head.appendChild(devicemotionScript);
            const iframe = document.querySelector("#onirix-frame");
            iframe.addEventListener('load', () => {
                iframe.contentWindow.postMessage('CSS;https://observatoriodelterritorio.onirix.com/ox-frame.css', '*');
                const handshakeMessage = { onirixEvent: 'HANDSHAKE' };
                iframe.contentWindow.postMessage(handshakeMessage, 'https://studio.onirix.com');
            });
        });
    },
    beforeUnmount() {
        window.removeEventListener('message', this.messageListener); 
    },
    methods: {
        onMessageReceived(msg) {
            if (msg.origin === 'https://studio.onirix.com') {
                const message = msg.data;
                if (message?.onirixEvent) {
                    switch(message.onirixEvent) {
                        case 'SCENE_LOAD_START':
                            this.prepareImageComparatorsForScene(message.params.oid);
                            break;
                        case 'ELEMENT_CLICK':
                            this.onElementClicked(message.params.oid);
                            break;
                    }
                }
            }
        },
        onElementClicked(oid) {
            console.log('Element clicked: ' + oid)
            if (this.imageComparators[oid] != null) {
                console.log('Activating image comparator')
                this.activeImageComparator = this.imageComparators[oid];
            }
        },
        datasheetToObject(datasheet) {
            const dataObject = {};
            datasheet.content.forEach(item => Object.keys(item).forEach( key => dataObject[key.toLowerCase()] = item[key] ));
            return dataObject;
        },
        prepareImageComparatorsForScene(oid) {
            OnirixAPIClient.getScene(oid).then(scene => {
                const imageComparators = {};
                scene.elements.map((element) => {
                    element.datasheets.map((datasheet) => {
                        if (datasheet.template.name === 'ImageToCompare') {
                            const dataObject = this.datasheetToObject(datasheet);
                            const imageAsset = this.assets.find(asset => asset.oid == dataObject.image);

                            if (imageAsset && dataObject.location) {
                                let imageComparator = imageComparators[element.oid]
                                if (null == imageComparator) {
                                    imageComparator = {
                                        locations: [],
                                        valid: false
                                    }
                                    imageComparators[element.oid] = imageComparator;
                                }

                                let location = imageComparator.locations.find( l => l.name == dataObject.location);
                                if (null == location) {
                                    location = {
                                        name: dataObject.location,
                                        images: []
                                    }
                                    imageComparator.locations.push(location);
                                }

                                location.images.push({
                                    year: dataObject.year,
                                    attribute: dataObject.attribute,
                                    oid: datasheet.oid,
                                    src: OnirixAPIClient.getAssetURL(imageAsset)
                                });

                                if (1 < location.images.length ) {
                                    imageComparator.valid = true
                                }
                            
                            } else {
                                console.error('Image not found or invalid datasheet', dataObject);
                            }
                        }
                    });
                });
                this.imageComparators = imageComparators;
            });
        },

        onImageComparatorClose() {
            this.activeImageComparator = null;
        }
    }
}

</script>

<style scoped>

iframe {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    border: none;
}

</style>