import { Expose, Type } from "class-transformer";
import { Asset } from "../assets/types";
import { Datasheet } from "../datasheet/types";

export enum SceneType {
    SURFACE = 0,
    IMAGE = 1,
    SPATIAL = 2,
    QRCODE = 3
}

export class Scene {

    @Expose() 
    oid: string;
    
    @Expose() 
    name: string;
    
    @Expose()
    type: SceneType;
    
    @Expose() 
    created: Date;
    
    @Expose() 
    updated: Date;

    @Type(() => MarkerImage)
    @Expose()
    marker: MarkerImage;

    @Type(() => QRCode)
    @Expose({ name: 'qrScene' })
    qrcode: QRCode;

    @Type(() => SceneElement)
    @Expose()
    elements: SceneElement[];

    @Type(() => Event)
    @Expose()
    events: Event[];

    @Type(() => Datasheet)
    @Expose()
    datasheets: Datasheet[];

}

export class MarkerImage {

    @Expose()
    oid: string;

    @Type(() => Asset)
    @Expose()
    asset: Asset;

    @Expose()
    width: number;

    @Expose()
    height: number;

    @Expose()
    quality: number;
    
    @Expose()
    maskActive: boolean;

    @Expose()
    mask: Asset;

}

export class QRCode {

    @Expose()
    oid: string;
    
    @Expose()
    code: string;

    @Expose()
    width: number;

    @Expose()
    height: number;
    
}

export enum SceneElementType {
    IMAGE = 0,
    OBJECT3D = 1,
    LABEL = 2,
    VIDEO = 3,
    AUDIO = 4,
    GROUP = 5
}

export class SceneElement {

    @Expose()
    oid: string;

    @Expose()
    name: string;

    @Expose()
    type: SceneElementType;

    @Expose()
    tx: number;

    @Expose()
    ty: number;

    @Expose()
    tz: number;

    @Expose()
    rx: number;

    @Expose()
    ry: number;

    @Expose()
    rz: number;

    @Expose()
    sx: number;

    @Expose()
    sy: number;

    @Expose()
    sz: number;

    @Expose()
    enabled: boolean;

    @Expose()
    opacity: number;

    @Type(() => Asset)
    @Expose()
    asset: Asset;

    @Expose()
    autoplay: boolean;

    @Expose()
    loop: boolean;

    @Expose()
    positional: boolean;

    @Expose()
    parentOid: string;

    @Type(() => Event)
    @Expose()
    events: Event[];

    @Type(() => Datasheet)
    @Expose()
    datasheets: Datasheet[];

}

export enum EventType {
    CLICK = 0,
    FINISH = 2,
    PROXIMITY_ENTER = 3,
    PROXIMITY_EXIT = 4,
    SCENE_LOAD = 5
}

export class Event {

    @Expose()
    type: EventType;

    @Expose()
    params: any;

    @Type(() => Action)
    @Expose()
    action: Action;

}

export enum ActionType {
    SHOW_URL = 0,
    ENABLE_ELEMENT = 1,
    DISABLE_ELEMENT = 2,
    ENABLE_ALL = 3,
    DISABLE_ALL = 4,
    TOGGLE = 5,
    SHOW_DATASHEET = 6,
    PLAY = 7,
    PAUSE = 8,
    PLAY_PAUSE = 9,
    POSITION = 10,
    ROTATION = 11,
    SCALE = 12,
    RESET = 13,
    PLAY_ANIMATION = 14,
    STOP_ANIMATION = 15,
    SERVICE_CALL = 16
}

export class Action {

    @Expose()
    type: ActionType;

    @Expose()
    delay: number;

    @Expose()
    params: any;

    @Type(() => SceneElement)
    @Expose()
    element: SceneElement;

    @Expose()
    url: string;
    
    @Expose()
    datasheet: Datasheet;

}