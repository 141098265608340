
import { plainToClass } from 'class-transformer';

export class ResponseWrapper<T> {

    code: number;
    status: string;
    messages: string[];
    data: T;

}

export abstract class API {
    
    protected authToken: string;
    
    protected basePath: string = 'https://studio.onirix.com/api/';

    constructor(authToken: string, basePath = 'https://studio.onirix.com/api/') {
        this.authToken = authToken;
        this.basePath = basePath;
    }
    
    protected async request<T> (endpoint: string, model: any): Promise<T> {
        
        const url = this.basePath + endpoint;
        
        const headers = {
            'Authorization': 'Bearer ' + this.authToken,
            'Content-Type': 'application/json',
            'App': 'Onirix-API'
        };

        const config = {
            headers
        };

        const response = await fetch(url, config);

        const responseWrapper: ResponseWrapper<T> = await response.json();
        if (responseWrapper.code == 0) { // Success
            return plainToClass(model, responseWrapper.data, { excludeExtraneousValues: true });
        }
        else if (responseWrapper.messages?.length > 0) {
            throw new Error(responseWrapper.messages[0]);
        }
        else {
            throw new Error("Server responded with an unexpecified error");
        }

    }

}