<template>

    <div :class="{ fadeout: !loading }">
        <img src="../../assets/logo.png" />
    </div>

</template>

<script>

export default {
    name: "Splash",
    data() {
        return {
            loading: true,
        }
    },
    mounted() {
        setTimeout(() => {
            this.loading = false;
        }, 3000);
    },
};

</script>

<style scoped>

div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

img {
    width: 60%;
    height: auto;
}

.fadeout {
    animation: fadeout 2s forwards;
}

@keyframes fadeout {
    to {
        opacity: 0;
        visibility: hidden;
    }
}

</style>
