<template>

    <div id="image-comparator" @mouseup="onMouseUp" @touchend="onMouseUp" @mousemove="onMouseMove" @touchmove="onMouseMove" @click="showControls">
        <ul class="left" :class="{fadeout: hideLists}">
            <li 
                :key="image.oid" 
                v-for="image of images"
                :class="{ selected: image.oid == imageLeft.oid }"
                @click="onLeftImageSelected(image, $event)">
                    <span>{{ image.year }}</span>
                    <span v-if="image.attribute" class="attribute">({{ image.attribute }})</span>
            </li>
        </ul>
        <div class="image-container">
            <span class="bubble bubble-left" :class="{fadein: hideLists}">{{imageLeft?.year}} <span v-if="imageLeft?.attribute">({{imageLeft?.attribute}})</span></span>
            <img class="left" :src="imageLeft?.src" ref="imageLeft" @load="onImageLoad" />
            <div class="slider" @mousedown="onMouseDown" @touchstart="onMouseDown" @click="$event.stopPropagation()" ref="slider"></div>
            <img class="right" :src="imageRight?.src" @load="onImageLoad" />
            <span class="bubble bubble-right" :class="{fadein: hideLists}">{{imageRight?.year}} <span v-if="imageRight?.attribute">({{imageRight?.attribute}})</span></span>
        </div>
        <ul class="right" :class="{fadeout: hideLists}">
            <li 
                :key="image.oid" 
                v-for="image of images"
                :class="{ selected: image.oid == imageRight.oid }"
                @click="onRightImageSelected(image, $event)">
                    <span>{{ image.year }}</span>
                    <span v-if="image.attribute" class="attribute">({{ image.attribute }})</span>
            </li>
        </ul>
        <div class="selector" :class="{open: locationsOpenend, fadeout: hideLists}">
            <div>
                <div class="selector-value">
                    <span class="close" @click="close">
                        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 13 20">
                            <g fill="none" fill-rule="evenodd">
                                <g fill="#000" fill-rule="nonzero">
                                    <g>
                                        <path d="M36.764 38.474L29.964 32l6.8-6.474c.65-.618.675-1.644.057-2.293-.019-.02-.038-.04-.058-.058-.693-.658-1.78-.658-2.474 0L25 32l9.29 8.825c.693.658 1.78.658 2.473 0 .65-.617.676-1.644.059-2.294-.019-.02-.038-.039-.058-.057z" transform="translate(-25 -92) translate(0 70)"/>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </span>
                    <span @click="openLocations($event)">{{ locationSelected?.name }}</span>
                    <span class="drop" @click="openLocations($event)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6">
                            <g fill="none" fill-rule="evenodd">
                                <g fill="#211F1F">
                                    <g>
                                        <g>
                                            <path d="M89 6L94 12 84 12z" transform="translate(-434 -34) translate(280 15) translate(70 13) matrix(1 0 0 -1 0 18)"/>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </span>
                </div>
                <ul class="options">
                    <li :key="location.name" v-for="location of locations"
                        v-bind:class="{selected: location.name === locationSelected.name}"
                        @click="onLocationSelected(location, $event)">{{ location.name }}</li>
                </ul>
            </div>
        </div>
        <span class="bubble bubble-help" :class="{fadein: showHelp, fadeout: showHelp && helpSeen}" >Toca la pantalla para mostrar los controles</span>

    </div>

    <div id="image-comparator-portrait-alert" v-bind:class="{hide: !isPortrait}">
        <div>
            <span class="close" @click="close">
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="20" viewBox="0 0 13 20">
                    <g fill="none" fill-rule="evenodd">
                        <g fill="#FFF" fill-rule="nonzero">
                            <g>
                                <path d="M36.764 38.474L29.964 32l6.8-6.474c.65-.618.675-1.644.057-2.293-.019-.02-.038-.04-.058-.058-.693-.658-1.78-.658-2.474 0L25 32l9.29 8.825c.693.658 1.78.658 2.473 0 .65-.617.676-1.644.059-2.294-.019-.02-.038-.039-.058-.057z" transform="translate(-25 -92) translate(0 70)"/>
                            </g>
                        </g>
                    </g>
                </svg>
            </span>
            <img src="../../assets/rotate-phone.svg" alt="Coloca el dispositivo en posición horizontal para utilizar el comparador" />
            <span>Coloca el dispositivo en posición horizontal para utilizar el comparador.</span>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ImageComparator',
    props: {
        imageComparator: Object
    },
    watch: { 
          imageComparator: function(newVal) {
              this.locations = newVal.locations;
              this.onLocationSelected(this.locations[0], null);
        }
    },
    data() {
        return {
            locations: [],
            locationSelected: null,
            images: [],
            imageLeft: null,
            imageRight: null,
            sliderMoving: false,
            isPortrait: true,
            locationsOpenend: false,
            hideLists: false,
            hideListsTimeout: null,
            helpSeen: false,
            showHelp: false,
            isFullscreen: false,
        }
    },
    mounted() {
        this.isFullscreen = null != document.fullscreenElement;
        if (!this.isFullscreen && document.body.requestFullscreen) {
            document.body.requestFullscreen();
        }

        window.addEventListener("resize", this.resize);
        this.handleOrientationChange();
        this.locations = this.imageComparator.locations;
        this.locations.sort( (a, b) => a.name.localeCompare(b.name));
        this.onLocationSelected(this.locations[0], null);
        this.showControls(false);
    },
    unmounted() {
        window.removeEventListener("resize", this.resize);
    },
    methods: {
        close() {
            if (!this.isFullscreen && document.exitFullscreen) {
                document.exitFullscreen();
            }
            this.$emit('close');
        },
        showHelpBubble() {
            if (this.helpSeen) {
                return
            }
            setTimeout(() => {
                this.showHelp = true;
                setTimeout(() => {
                    this.helpSeen = true;
                }, 2000);
            }, 5000);
        },
        startHideControls(force = false) {
            if (null != this.hideListsTimeout) {
                clearTimeout(this.hideListsTimeout);
            }
            if (force) {
                this.hideLists = true;
                if (this.showHelp) {
                    this.helpSeen = true;
                }
                return;
            }
            this.hideListsTimeout = setTimeout(() => {
                if (!this.hideLists && !this.locationsOpenend) {
                    this.hideLists = true;
                    this.showHelpBubble();
                }
            }, 3000);
        },
        showControls(helpSeen = true) {
            if (this.locationsOpenend) {
                this.locationsOpenend = false;
                this.startHideControls();
                return ;
            }
            if (!this.hideLists) {
                this.startHideControls(true);
                return ;
            }
            this.hideLists = false;
            this.startHideControls();
            this.helpSeen = helpSeen;
        },
        onMouseDown(event) {
            event.stopPropagation();            
            this.sliderMoving = true;
        },
        onMouseMove(event) {      
            event.preventDefault();      
            if (this.sliderMoving) {
                this.startHideControls(true);
                const leftImage = this.$refs.imageLeft;
                const slider = this.$refs.slider;
                const bounding = leftImage.getBoundingClientRect();
                const clientX = event.touches?.length >= 1 ? event.touches[0].clientX : event.clientX;
                if (clientX - bounding.x > 0 && clientX - bounding.x < bounding.width) {
                    leftImage.style.clip = `rect(0px, ${clientX - bounding.left}px, ${bounding.height}px, 0px)`;
                    slider.style.left = `calc(${clientX - bounding.left}px - 27px)`;
                }
            }
        },
        onMouseUp() {            
            this.sliderMoving = false;
        },
        resize() {
            const leftImage = this.$refs.imageLeft;
            const slider = this.$refs.slider;
            const bounding = leftImage.getBoundingClientRect();
            const x = parseInt(bounding.width / 2);
            leftImage.style.clip = `rect(0px, ${x}px, ${bounding.height}px, 0px)`;
            slider.style.left = `calc(${x}px - 27px)`;
            this.handleOrientationChange();
        },
        onImageLoad() {
            this.resize();
        },
        onLeftImageSelected(image, event) {
            event.stopPropagation();           
            this.imageLeft = image;
            this.startHideControls();
        },
        onRightImageSelected(image, event) { 
            event.stopPropagation();         
            this.imageRight = image;
            this.startHideControls();
        },
        handleOrientationChange() {
           this.isPortrait = window.innerHeight >= window.innerWidth;
           if (!this.isPortrait) {
               this.showControls(false);
           }
        },
        onLocationSelected(locationSelected, event) {
            if (event) {
                event.stopPropagation();
            }
            this.locationSelected = locationSelected;
            this.images = this.locationSelected.images;
            this.images.sort( (a, b) => a.year.localeCompare(b.year));
            this.imageLeft = this.images[0];
            this.imageRight = this.images[1];
            this.locationsOpenend = false;
        },
        openLocations(event) {
            event.stopPropagation();
            this.locationsOpenend = !this.locationsOpenend;
            if (!this.locationsOpenend) {
                this.startHideControls();
            }
        }
    }
    
}

</script>

<style scoped>

#image-comparator-portrait-alert {
    position: fixed;
    top: 0; left: 0; right: 0; bottom: 0;
    width: 100vw;
    height: 100vh;
    background-color: #00a6ab;
    background-image: url('../../assets/rotate-phone-background.svg');
    background-repeat: no-repeat;
    background-position: calc(100% + 170px) calc(100% + 65px);
    font-family: 'Montserrat';
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    z-index: 10;
}

#image-comparator-portrait-alert > div {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

#image-comparator-portrait-alert > div > span {
    padding: 30px;
}

#image-comparator-portrait-alert > div > img {
    width: 169px;
    height: 183px;
    position: unset;
}

#image-comparator-portrait-alert span.close {
    position: absolute;
    top: 22px;
    left: 25px;
    right: unset;
    background: unset;
    display: block;
    width: 1em;
    height: 1em;
    border-radius: 2em;
    padding: 0.5em;
}

#image-comparator-portrait-alert span.close svg {
    width: 100%;
    height: 100%;
}

#image-comparator {
    position: relative;
    top: 0; left: 0; right: 0; bottom: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    background: #fff;
    font-family: 'Montserrat';
}

#image-comparator ul{
    position: absolute;
    top: 0;  bottom: 0;
    height: 100vh;
    width: 100px;
    list-style: none;
    padding: 0px;
    overflow: auto;
    margin: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#image-comparator ul.left{
    left: 0;
    z-index: 1;
}

#image-comparator ul.right{
    right: 0;
    z-index: 1;
}

.hide {
    display: none;
}

li {
    box-sizing: border-box;
    height: 50px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    background-color: rgba(255, 255, 255, 0.9);
    font-size: 15px;
    font-weight: 600;
}

span.attribute {
    font-size: 12px;
    font-weight: normal;
}

li.selected {
    background-color: rgba(0, 166, 171, 0.9);
    color: #FFF;
}

div.image-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    z-index: 0;
    background-color: #000;
}


#image-comparator img {
    position: absolute;
    margin: auto;
    top: 0; left: 0; right: 0; bottom: 0;
    width: 100%;
    object-fit: contain;
    user-select: none;
}

div.slider {
    width: 54px;
    height: 54px;
    position: absolute;
    top: calc(50% - 27px);
    left: calc(50% - 27px);
    z-index: 2;
    cursor: ew-resize;
    background-image: url('../../assets/slider.svg');
    background-repeat: no-repeat;
    background-position: center center;
}
div.slider::after {
    content: ' ';
    width: 2px;
    height: calc(50vh - 27px);
    background-color: #FFF;
    position: absolute;
    top: calc(-50vh + 27px);
    left: 26px;
}

div.slider::before {
    content: ' ';
    width: 2px;
    height: calc(50vh - 27px);
    background-color: #FFF;
    position: absolute;
    top: 54px;
    left: 26px;
}

img.left {
    z-index: 1;
}

.selector {
    background-color: rgba(255, 255, 255, 0.9);
    font-size: 15px;
    font-weight: 600;
    border-radius: 22px;
    height: 44px;
    box-sizing: border-box;
    padding: 0px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translateX(-50%) ;
    z-index: 1;
    min-width: 150px;
}

.selector > div {
    position: relative;
    width: 100%;
}

.selector-value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.selector-value > *:not(:last-child) {
    margin-right: 10px;
}

.selector-value .close,
.selector-value .drop {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#image-comparator .selector-value + .options {
    display: none
}

#image-comparator .selector.open .selector-value + .options {
    display: block
}

#image-comparator .selector.open .selector-value .drop {
    transform-origin: center;
    transform: rotate(180deg);
}

#image-comparator .options {
    top: 54px;
    left: -13px;
    height: fit-content;
    width: calc(100% + 26px);
    max-height: 75vh;
    border-radius: 6px;
    overflow: auto;
}

#image-comparator .options li {
    height: 42px;
    padding: 12px 14px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: bold;
}

#image-comparator .options li:first-of-type {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

#image-comparator .options li:last-of-type {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

#image-comparator li {
    box-sizing: border-box;
    height: 50px;
}

span.bubble {
    height: 38px;
    padding: 10px 17px;
    border-radius: 19px;
    background-color: rgba(59, 62, 62, 0.76);
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 600;
    color: #f1f3fe;
}

.bubble.bubble-left,
.bubble.bubble-right {
    top: 20px;
    left: 20px;
    background-color: rgba(255, 255, 255, 0.76);
    color: #211f1f;
    z-index: 10;
    transform: unset;
    opacity: 0;
    visibility: hidden;
}

.bubble.bubble-right {
    left: unset;
    right: 20px;
}

.bubble.bubble-help {
    opacity: 0;
    visibility: hidden;
}

span.bubble > span {
    margin-left: 5px;
} 

.fadein {
    animation: fadein 0.5s forwards;
}

@keyframes fadein {
    0% {
        opacity: 0;
        visibility: hidden;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }
}

.fadeout {
    animation: fadeout 0.5s forwards;
}

@keyframes fadeout {
    0% {
        opacity: 1;
        visibility: visible;
    }
    100% {
        opacity: 0;     
        visibility: hidden;
    }
}

</style>