import { Expose } from "class-transformer";

export enum AssetType {
    IMAGE = 0,
    OBJECT3D = 1,
    ENVMAP = 2,
    AUDIO = 7,
    VIDEO = 8
}

export class Asset {

    @Expose() 
    oid: string;
    
    @Expose() 
    name: string;
    
    @Expose() 
    filename: string;
    
    @Expose() 
    type: AssetType;
    
    @Expose() 
    created: Date;
    
    @Expose() 
    updated: Date;

}