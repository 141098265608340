import { Expose } from "class-transformer";

export class Datasheet {

    @Expose()
    oid: string;

    @Expose()
    name: string;

    @Expose()
    template: any;

    @Expose()
    content: any;

    @Expose()
    created: Date;

    @Expose()
    updated: Date;

}

export class DatasheetListElement {

    @Expose()
    datasheet_oid: string;
    
    @Expose()
    datasheet_name: string;
    
    @Expose()
    container_name: string;
    
    @Expose()
    element_type: string;

}
