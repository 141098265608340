
import { API } from '../api';
import { Datasheet, DatasheetListElement } from './types'

const resourcePath = 'datasheets';

export class DatasheetService extends API {

    getDatasheets() {
        let query = `${resourcePath}`;
        return this.request<DatasheetListElement[]>(query, DatasheetListElement);
    }

    getDatasheet(oid: string) {
        let query = `${resourcePath}/${oid}`;
        return this.request<Datasheet[]>(query, Datasheet);
    }

}