<template>
    <div id="header">
       <h1>Pildoras RA</h1>
       <h2>Una guía basada en técnicas de Realidad Aumentada</h2>
    </div>
    <ul>
        <li :key="project.oid" v-for="project in projects">
            <router-link :to="project.oid">{{ project.name }}</router-link>
        </li>
    </ul>
</template>

<script>

import OnirixAPI from 'onirix-api'
import Constants from '../../constants'

const OnirixAPIClient = new OnirixAPI(Constants.API_TOKEN, `${Constants.ONIRIX_HOST}/api/`);

export default {
    name: "HomeView",
    data() {
        return {
            projects: []
        }
    },
    async created() {
        const projects = await OnirixAPIClient.getProjects(); 
        this.projects = projects.filter(p => p.public);
    }
    
}

</script>

<style scoped>

#header {
    text-align: center;
    background: #00a6ab;
    color: #FFFFFF;
    padding: 4em 2em;
    background-image: url('../../assets/home-background.svg');
    background-repeat: no-repeat;
    background-position: bottom right;
    position: relative;
}

#header::before {
    content: '';
    background-image: url('../../assets/home-logo.svg');
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    width: 34px;
    height: 35px;
    top: 20px;
    left: 20px;
}

#header h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

#header h2 {
    font-size: 1.4em;
    font-weight: 400;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

li {
    border: 1px solid #ddd;
    padding: 2em;
    font-weight: bold;
    position:relative;
}

li::after {
    content: '';
    background-image: url('../../assets/go-to.svg');
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    width: 8px;
    height: 100%;
    top: 0px;
    right: 20px;
}

li a {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    font-size: 1.1em;
    color: #333333;
}

</style>