import { Expose, Type } from "class-transformer";
import { Datasheet } from "../datasheet/types";

export class Project {

    @Expose() 
    oid: string;
    
    @Expose() 
    name: string;
    
    @Expose() 
    created: Date;
    
    @Expose() 
    updated: Date;

    @Expose() 
    public: boolean;
    
    @Expose() 
    publicToken: string;

    @Type(() => Datasheet)
    @Expose()
    datasheets: Datasheet[];

}