import 'reflect-metadata';

import { API } from './api'
import { AssetService } from './assets';
import { DatasheetService } from './datasheet';
import { ProjectService } from './projects'
import { SceneService } from './scene';
import { applyMixins } from './utils'

class OnirixAPI extends API {}
interface OnirixAPI extends ProjectService, SceneService, AssetService, DatasheetService {}

applyMixins(OnirixAPI, [ProjectService, SceneService, AssetService, DatasheetService]);

export default OnirixAPI;