import { API } from '../api';
import { Scene } from './types'

const resourcePath = 'targets';

export class SceneService extends API {

    getScenes(projectOid: string) {
        let query = `${resourcePath}?projectOid=${projectOid}`;
        return this.request<Scene>(query, Scene);
    }
    
    getScene(oid: string) {
        let query = `${resourcePath}/${oid}`;
        return this.request<Scene>(query, Scene);
    }

}