<template>
  <Splash></Splash>
  <router-view></router-view>
</template>

<script>
import Splash from './components/shared/Splash.vue'

export default {
  name: 'App',
  components: {
    Splash
  }
}
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap');

html, body {
    margin: 0;
    padding: 0;
}

#app {
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

</style>
